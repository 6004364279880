const LIMITS = {
  free: {
    websites: 1,
  },
  basic: {
    websites: 1,
  },
  premium: {
    websites: 2,
  },
  enterprise: {
    websites: 5,
  },
  "saaszilla-1": {
    websites: 1,
  },
  "saaszilla-2": {
    websites: 1,
  },
  "saaszilla-3": {
    websites: 10,
  },
  "saaszilla-4": {
    websites: 50,
  },
};

export default LIMITS;
